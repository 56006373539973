import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { useDebounce } from '../../../../libraries/useDebounce';
import { symbolLookUp } from '../../../../api/fasttrack-service';
import Select from 'react-select';
import { DEFAULT_BENCHMARK_NAME, DEFAULT_BENCHMARK_SYMBOL } from "../../../../config/constant";

interface AddBenchmarkModalProps {
  isOpen: boolean,
  onClose: () => void,
  onSave: (e: { symbol: string, name: string }) => void,
  isBenchmarkAdding: boolean,
  selectedBenchmark?: any,
}

const AddBenchmarkModal: React.FC<AddBenchmarkModalProps> = ({
                                                               isOpen,
                                                               onClose,
                                                               onSave,
                                                               isBenchmarkAdding,
                                                               selectedBenchmark
                                                             }) => {

  const textColorSecondary = 'gray.500';
  const brandColor = useColorModeValue('brand.500', 'white');
  const [securityName, setSecurityName] = useState<string>('');
  const [errorFlag, setErrorFlag] = useState<boolean>(false);
  const [isSecurityLoading, setIsSecurityLoading] = useState<boolean>(false);
  const [securityList, setSecurityList] = useState([]);
  const [security, setSecurity] = useState<any>(null);
  const debouncedFilter = useDebounce(securityName, 700);
  const [useDefaultSPX, setUseDefaultSPX] = useState(false);

  useEffect(() => {
    if (securityName.length > 1) {
      setIsSecurityLoading(true);
      symbolLookUp(securityName)
        .then(({ symbols }) => {
          const list = symbols.map((item: any) => ({
            value: item.symbol,
            label: `${item.symbol} -- ${item.name}`
          }))
          setSecurityList(list);
        })
        .catch((err) => {
          console.error(err.message);
          setSecurityList([]);
        })
        .finally(() => setIsSecurityLoading(false))
    }
  }, [debouncedFilter])

  useEffect(() => {
    if (selectedBenchmark) {
      setSecurityName(selectedBenchmark?.ticker);
      if (selectedBenchmark?.ticker !== selectedBenchmark?.name) {
        setSecurity({
          value: selectedBenchmark?.ticker,
          label: `${selectedBenchmark?.ticker} -- ${selectedBenchmark.name}`
        });
      }
    } else {
      setSecurityName('');
      setSecurityList([]);
      setSecurity(null);
    }
  }, [selectedBenchmark]);

  useEffect(() => {
    if (useDefaultSPX) {
      setSecurity({
        value: DEFAULT_BENCHMARK_SYMBOL,
        label: `${DEFAULT_BENCHMARK_SYMBOL} -- ${DEFAULT_BENCHMARK_NAME}`
      });
    } else {
      setSecurity(null);
    }
  }, [useDefaultSPX]);

  const save = () => {
    if (!security) {
      setErrorFlag(true);
    } else {
      onSave({
        symbol: security?.value,
        name: security?.label,
        ...(selectedBenchmark ? { securityId: selectedBenchmark?.securityId } : {}),
      });
    }
  }

  const handleClose = () => {
    onClose();
    setSecurityName('');
    setSecurity(null);
    setErrorFlag(false);
  }

  return (
    <Modal isOpen={isOpen} onClose={() => handleClose()} isCentered>
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader>Update Benchmark</ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          <Text mb="12px" color={textColorSecondary} fontSize="sm">
            Please ensure the ticker symbol and name are accurate.</Text>
          {
            selectedBenchmark &&
            <Box mb="12px">
              <Text>Ticker Name (Suggested)</Text>
              <Text fontWeight='500' fontSize='lg' color='brand.500'>{selectedBenchmark?.name}</Text>
            </Box>
          }

          <FormControl id="SecurityName" isRequired>
            {errorFlag ? (
              <FormHelperText color="red">
                {!security ? 'Security is required' : 'Security not found'}
              </FormHelperText>
            ) : null}

            <FormLabel>Ticker Symbol</FormLabel>
            <Select
              isLoading={isSecurityLoading}
              value={security}
              isClearable
              placeholder="Input Security Name"
              onInputChange={(value: string) => setSecurityName(value)}
              onChange={(value: string) => setSecurity(value)}
              isSearchable
              isDisabled={useDefaultSPX}
              options={securityList}
            />
          </FormControl>

          <FormControl mt={2}>
            <Flex align='center'>
              <Checkbox
                defaultChecked={useDefaultSPX}
                checked={useDefaultSPX}
                onChange={() => setUseDefaultSPX(prev => !prev)}
                colorScheme='brandScheme'
                me='10px'
              />
              <Text fontSize='md' fontWeight='bold'>
                Use default S&P 500 Index
              </Text>
            </Flex>
          </FormControl>

          {
            security && <Box mt="8px">
              <Text color={brandColor} fontSize="lg" fontWeight="500">Found Ticker</Text>
              <Flex>
                <Text fontSize="md" fontWeight="700" mr="4px">Ticker Symbol: </Text>
                <Text fontSize="md">{security?.value}</Text>
              </Flex>
              <Flex>
                <Text fontSize="md" fontWeight="700" mr="4px">Name: </Text>
                <Text fontSize="md">{security?.label?.split('--')[1].trim()}</Text>
              </Flex>
            </Box>
          }
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="brandScheme" mr={3} isLoading={isBenchmarkAdding}
                  onClick={() => save()}>Update</Button>
          <Button colorScheme="brandScheme" variant="ghost" onClick={() => handleClose()}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AddBenchmarkModal;
